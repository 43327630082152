import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link } from 'gatsby'
import { SEO, Layout, CarouselSlider, HMOSlider, VerticalCard } from '../../components/common'
import { Container, Row, Col, ButtonToolbar, Button, Image } from 'react-bootstrap'
import ShowBlank from '../shared/ShowBlank'
import './home.scss'

const Home = ({ pageContext }) => {
  const { homePage, topNav, accreditedHmos } = pageContext
  return (
    <>
      <SEO title={topNav.name} favicon={topNav.favicon} />
      <Layout activeNav='home' data={topNav}>
        <CarouselSlider data={homePage.banners} />
        <HMOSlider data={accreditedHmos} />
        <Container>
          <section className="book-appointment mt-5 md-mt-4 md-py-4 text-center">
            <h2 className="text-primary mb-0 text-title">Book Appointment Now!</h2>
            <h6 className="text-info font-normal my-3">Select what you want to book.</h6>
            <div className="d-flex flex-row my-4">
              <Link to="/our-doctors" className="text-decoration-none sm-py-1">
                <div className="btn-dark-slate-blue text-white mx-2 rounded">
                  <div className="card-links">
                    <Image src={require("../../images/doctor.png")} className="card-image mb-4" />
                    <h5 className="mt-3 mb-0">OUR DOCTORS</h5>
                  </div>
                </div>
              </Link>
              <Link to="/our-services" className="text-decoration-none sm-py-1">
                <div className="btn-danger text-white mx-2 rounded">
                  <div className="card-links">
                    <Image src={require("../../images/service.png")} className="card-image mb-4" />
                    <h5 className="mt-3 mb-0">OUR SERVICES</h5>
                  </div>
                </div>
              </Link>
            </div>
          </section>

          <section className="our-promos">
            <h2 className="text-dark-slate-blue mb-2 text-title">Our Promos</h2>
            {
              homePage.ourPromos.promos && homePage.ourPromos.promos.length > 0
                ? <Fragment>
                  <Row>
                    {homePage.ourPromos.promos.slice(0, 3).map((data, i) =>
                      <Col lg={4} md={4} sm={12} className="my-2" key={i}>
                        <VerticalCard
                          img_url={data.banner}
                          title={data.title}
                          description={data.shortDescription}
                          slug={"/promo/" + data.slug}
                        />
                      </Col>
                    )}
                  </Row>
                  <ButtonToolbar className="justify-content-center mt-3 mb-5">
                    <Button variant="dark-slate-blue" size='lg' as={Link} to="/promos-and-events">VIEW MORE</Button>
                  </ButtonToolbar>
                </Fragment>
                : <ShowBlank />
            }
          </section>

          <section className="our-promos">
            <h2 className="text-dark-slate-blue mb-2 text-title">Our Events</h2>
            {
              homePage.ourPromos.events && homePage.ourPromos.events.length > 0
                ? <Fragment>
                  <Row>
                    {homePage.ourPromos.events.slice(0, 3).map((data, i) =>
                      <Col lg={4} md={4} sm={12} className="my-2" key={i}>
                        <VerticalCard
                          img_url={data.banner}
                          title={data.title}
                          description={data.shortDescription}
                          slug={"/event/" + data.slug}
                        />
                      </Col>
                    )}
                  </Row>
                  <ButtonToolbar className="justify-content-center mt-3 mb-5">
                    <Button variant="dark-slate-blue" size='lg' as={Link} to="/promos-and-events">VIEW MORE</Button>
                  </ButtonToolbar>
                </Fragment>
                : <ShowBlank />
            }
          </section>

          <section className="our-facilities">
            <h2 className="text-dark-slate-blue mb-2 text-title">Our Facilities</h2>
            {
              homePage.ourSuites && homePage.ourSuites.length > 0
                ? <Fragment>
                  <Row>
                    {homePage.ourSuites.slice(0, 3).map((data, i) =>
                      <Col lg={4} md={4} sm={12} className="my-2" key={i}>
                        <VerticalCard
                          img_url={data.banner}
                          title={data.title}
                          description={data.shortDescription}
                          slug={"/suite/" + data.slug}
                        />
                      </Col>
                    )}
                  </Row>
                  <ButtonToolbar className="justify-content-center mt-3 mb-5">
                    <Button variant="dark-slate-blue" size='lg' as={Link} to="/our-facilities">VIEW MORE</Button>
                  </ButtonToolbar>
                </Fragment>
                : <ShowBlank />
            }
          </section>

          <section className="careers">
            <h2 className="text-dark-slate-blue mb-2 text-title">Careers</h2>
            {
              homePage.careers && homePage.careers.length > 0
                ? <Fragment>
                  <Row>
                    {homePage.careers.slice(0, 3).map((data, i) =>
                      <Col lg={4} md={4} sm={12} className="my-2" key={i}>
                        <VerticalCard
                          img_url={data.banner}
                          title={data.title}
                          description={data.shortDescription}
                          slug={"/career/" + data.slug}
                        />
                      </Col>
                    )}
                  </Row>
                  <ButtonToolbar className="justify-content-center mt-3 mb-5">
                    <Button variant="dark-slate-blue" size='lg' as={Link} to="/careers">VIEW MORE</Button>
                  </ButtonToolbar>
                </Fragment>
                : <ShowBlank />
            }
          </section>
        </Container>
      </Layout>
    </>
  )
}

Home.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Home
